// @ts-nocheck

import {
  useAppDispatch,
  useAppSelector,
} from '../../../engine/hooks/redux.hooks';
import svgjson from 'svgson';
import _ from 'lodash';
import { SyncOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  Button,
  Layout,
  Table,
  theme,
  Typography,
  Select,
  Popconfirm,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  createUnits,
  getPosts,
  increment,
  triggerSyncBackend,
} from './Units.slice';
import { UnitDetail } from '../UnitDetail/Unit.detail';
import {
  colors,
  directions,
  formatCurrency,
  statusOption,
  statusOptionSimple,
} from '../../../helpers/constants';
import { clearUnit, getUnit, updateUnit } from '../UnitDetail/Unit.slice';
import CustomForm from '../../../components/CustomForm';
import { AppContext } from '../../../engine/contexts/App.context';
import axios, { AxiosResponse } from 'axios';
import { getConfiguration } from '../../Configuration/Configuration.slice';
const { Content, Sider, Header, Footer } = Layout;

export function UnitsList() {
  const units = useAppSelector((state) => state.units);

  const { configuration, loading } = useAppSelector(
    (state) => state.configuration,
  );

  const dispatch = useAppDispatch();

  // const context = useContext(AppContext);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [searchName, setSearchName] = useState('');
  const [searchId, setSearchId] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [rows, setExpandRows] = useState([]);

  useEffect(() => {
    dispatch(increment());
    dispatch(getPosts());
    dispatch(getConfiguration('valoribox'));
  }, []);

  const parseJsonData = async (json) => {
    let newUnits = [];
    json.children.map((child) => {
      if (child.name === 'polygon' && child?.attributes.id !== undefined) {
        newUnits.push({
          id: child.attributes.id,
        });
      }
    });
    return newUnits;
  };

  const sendJson = async (items) => {
    dispatch(createUnits(items));
  };

  const triggerSync = async () => {
    // @ts-ignore
    dispatch(triggerSyncBackend());
  };

  const deleteUnits = async () => {};

  const syncSvg = async () => {
    // @ts-ignore
    axios({
      url: configuration.svg_uri,
      method: 'GET',
      responseType: 'text/plain',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .then(svgjson.parse)
      .then(parseJsonData)
      .then(sendJson);
  };

  const updateStatus = (unit) => {
    const expanded = rows.find((e) => e === unit._id) !== undefined;

    // if (expanded) {
    //   setExpandRows([unit._id]);
    // } else {
    //   setExpandRows([]);
    //   dispatch(clearUnit());
    // }

    dispatch(updateUnit(unit));
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      },
      width: '10%',
    },
    {
      title: 'Nom',
      dataIndex: 'info.name',
      key: 'name',
      render: (n, record) => record.info.name,
      sorter: (a, b) => {
        if (a.info.name < b.info.name) {
          return -1;
        }
        if (a.info.name > b.info.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        if (type === 'selling') {
          return 'Vente';
        } else {
          return 'Location';
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',

      render: (s, record) => {
        return (
          <Select
            value={s}
            style={{ width: 150 }}
            onChange={(newValue) =>
              updateStatus({ ...record, status: newValue })
            }
            className={'colorfull'}
          >
            {statusOption.map((option) => (
              <Select.Option key={option?.value} value={option?.value}>
                {option?.label()}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Orientation',
      dataIndex: 'info.orientation',
      key: 'status',
      width: '25%',
      render: (s, record) => {
        console.log(s, record);
        // @ts-ignore
        return (
          <Select
            mode={'multiple'}
            className={'orientation'}
            value={record.info.orientation}
            style={{ width: '100%' }}
            onChange={(newValue) => {
              updateStatus({
                ...record,
                info: { ...record.info, orientation: newValue },
              });
            }}
          >
            {directions.map((option) => (
              <Select.Option key={option?.value} value={option?.value}>
                {option?.label()}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Etage',
      dataIndex: 'floor',
      key: 'floor',
      width: '50px',

      render: (n, record) => record.info.floor,
    },
    {
      title: 'Pièces',
      width: '50px',
      dataIndex: 'nbPieces',
      key: 'nbPieces',
      render: (n, record) => record.info.numberRoom,
    },
    {
      title: 'Prix',
      width: '15%',

      dataIndex: 'price',
      key: 'price',
      render: (n, record) => formatCurrency(record.info.price),
    },
  ];

  const onChangeData = (form, changedFields, allFields) => {
    setSearchName(form.getFieldValue('name'));
    setSearchId(form.getFieldValue('id'));
    setSearchType(form.getFieldValue('type'));
    setSearchStatus(form.getFieldValue('status'));
  };

  const inputs = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Id',
          name: 'id',
          value: '',
          type: 'input',
          capitalize: false,
          col: 4,
        },
        {
          label: 'Nom',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          col: 4,
        },
        {
          label: 'Type',
          name: 'type',
          value: 'all',
          type: 'select',
          selectContent: [
            { label: 'Tous', value: 'all' },
            { label: 'Location', value: 'renting' },
            { label: 'Vente', value: 'selling' },
          ],
          col: 4,
        },
        {
          label: 'Status',
          name: 'status',
          value: 'all',
          type: 'select',
          selectContent: [
            {
              label: 'Tous',
              value: 'all',
            },
            ...statusOptionSimple,
          ],
          col: 4,
        },
      ],
    },
  ];

  const manipulateArraySource = () => {
    const copy = _.cloneDeep(units.entities);

    let filter = {};

    if (searchType !== '') {
      // @ts-ignore
      filter.type = searchType;
    }

    if (searchName !== '') {
      // @ts-ignore

      // @ts-ignore
      filter.name = searchName;
    }

    if (searchId !== '') {
      // @ts-ignore

      // @ts-ignore
      filter.id = searchId;
    }

    if (searchStatus !== '') {
      // @ts-ignore
      filter.status = searchStatus;
    }

    let filtered = copy.filter((unit) => {
      for (var key in filter) {
        if (key === 'name') {
          if (
            unit.info.name
              .toString()
              .toLowerCase()
              .indexOf(filter[key].toLowerCase()) < 0
          ) {
            return false;
          } else {
            return true;
          }
        } else if (filter[key] !== 'all') {
          if (
            unit[key] === undefined ||
            unit[key]
              .toString()
              .toLowerCase()
              .indexOf(filter[key].toLowerCase()) < 0
          )
            return false;
          //
        }
      }
      return true;
    });

    return filtered.sort((b, a) => {
      if (a.id > b.id) {
        return -1;
      }
      if (b.id > a.id) {
        return 1;
      }
      return 0;
    });
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // record: row의 data
        // rowIndex: row의 index
        // event: event prototype

        console.log(event.target.className);

        if (
          event.target.className === 'link-table' ||
          event.target.className === 'ant-select-selection-overflow' ||
          event.target.className === 'item-color-select' ||
          event.target.className === 'ant-select-selector' ||
          event.target.className === 'rc-virtual-list-holder-inner' ||
          event.target.className === 'ant-select-item-option-content' ||
          event.target.className === 'orientation' ||
          event.target.className === undefined ||
          event.target.className === 'ant-menu-title-content'
        ) {
          return false;
        } else {
          const expanded = rows.find((r) => r === record._id) === undefined;
          if (expanded) {
            setExpandRows([record._id]);
          } else {
            setExpandRows([]);
            dispatch(clearUnit());
          }

          return true;
        }
      },
    };
  };

  return (
    <>
      <Header
        style={{
          padding: 10,
          background: colorBgContainer,
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title
          style={{
            marginBottom: 0,
            marginLeft: '20px',
            color: ' rgb(0 21 41)',
            fontWeight: 300,
            fontSize: '25px',
          }}
          className={''}
        >
          Gestion des lots
        </Typography.Title>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <Popconfirm
            title="Confirmer la synchronisation des lots ?"
            onConfirm={triggerSync}
          >
            <Button
              style={{
                justifySelf: 'flex-end',
                display: 'flex',
                marginRight: '10px',
              }}
              type={'primary'}
              // onClick={syncSvg}
              loading={units.loading}
              disabled={units.loading}
              icon={<SyncOutlined style={{ marginTop: '5px' }} />}
            >
              Synchroniser les lots
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Confirmer la suppression des lots ?"
            onConfirm={deleteUnits}
            onOpenChange={() => console.log('open change')}
          >
            <Button
              style={{ justifySelf: 'flex-start', display: 'flex' }}
              loading={units.loading}
              disabled={units.loading}
              icon={<DeleteOutlined style={{ marginTop: '5px' }} />}
            >
              Vider les lots
            </Button>
          </Popconfirm>
        </div>
      </Header>
      <Content
        style={{
          margin: '24px 16px 0',
          overflow: 'initial',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            padding: 24,
            background: colorBgContainer,
          }}
        >
          <CustomForm
            dataSource={inputs}
            onChangeData={onChangeData}
            labelCol={24}
            wrapperCol={24}
            resetOnFinish={undefined}
            refForm={undefined}
            onValuesChange={undefined}
            handleSubmit={undefined}
          />
          <Table
            onRow={onRow}
            pagination={{
              hideOnSinglePage: false,
              position: ['bottomRight'],
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
            }}
            dataSource={manipulateArraySource()}
            columns={columns}
            rowKey="_id"
            expandable={{
              expandedRowKeys: rows,
              expandedRowRender: (unit, index, indent, expanded) => (
                <UnitDetail
                  unitId={unit._id}
                  key={unit._id}
                  expanded={expanded}
                  onClose={() => {
                    setExpandRows([]);
                    dispatch(clearUnit());
                  }}
                />
              ),
              rowExpandable: (record) => true,
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandRows([record._id]);
                } else {
                  setExpandRows([]);
                  dispatch(clearUnit());
                }
              },
              onExpandedRowsChange: (expandedRows) => {
                // @ts-ignore
                //setExpandRows(expandedRows);
              },
            }}
          />
        </div>
      </Content>
    </>
  );
}
