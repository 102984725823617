import { formatCurrency } from '../../helpers/constants';
export const currencyFormatter = (value, separator = true) => {
  const removeChar = value.replace("'", '');

  var removeSpecialChar = removeChar.replace(
    /[`~!@#$%^&*()_|+\=?;:'",<>\{\}\[\]\\\/]/gi,
    '',
  );

  const numberOnly = removeSpecialChar.replace(/[^\d.-]/g, '');
  // Old way
  // return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  if (numberOnly === '') {
    return 0;
  }

  let parserFoat = parseFloat(numberOnly).toFixed(3).slice(0, -1);

  if (separator) {
    return parserFoat.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  } else {
    return parserFoat;
  }
};
